<template>
    <div class="container">
        <back-btn></back-btn>
        <page-title-card></page-title-card>
        <div class="word-list">
            <div class="item-word" v-for="item in wordList" :key="item.id">
                <div class="word-en">
                    <div>{{ item.wordEn }}</div>
                    <div @click="startSpeak(item)">
                        <play-sound :play="isSpeaking && word == item.wordEn" color="#54211d"></play-sound>
                    </div>                    
                </div>
                <div class="word-ch">{{ item.wordCh }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            word: '',
            wordId: '',
            voices: [],
            speakCount: 0,
            isSpeaking: true,
            timeoutId: null,
        }
    },


    beforeDestroy() {
        this.stopWord()
    },

    computed: {
        // 路由参数
        routeParams () {
            return this.$route.params;
        },

        // 单元词汇量
        wordList () {
            return this.$store.getters.getWordList(this.routeParams.volumeId, this.routeParams.catalogId);
        }
    },

    methods: {
        // 开始朗读
        startSpeak(item) {
            this.stopWord();
            this.word = item.wordEn;
            this.wordId = item.id;
            this.speakCount = 0;
            this.isSpeaking = true;
            this.speakWord();
        },

        // 停止朗读
        stopWord() {
            this.isSpeaking = false;
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.$speck.cancelTTS();
        },

        // 朗读
        speakWord() {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }

            if (!this.isSpeaking) {
                return;
            };

            this.$speck.initTTS({
                text: this.word,
                wordId: this.wordId,
                onend: () => {
                    this.speakCount++;
                    if (this.speakCount >= 3) {
                        this.stopWord();
                        return;
                    };

                    this.timeoutId = setTimeout(() => {
                        this.speakWord();
                    }, 1500);
                }
            });
        },
    }
}
</script>

<style lang="scss" scoped>
    .container {
        padding: 20px 0;
        display: block;
        position: relative;

        .word-list {
            padding: 0 40px;
            overflow: auto;
            height: calc(100vh - 140px);
            overflow-y: scroll;
            margin-top: 60px;
            .item-word {
                margin-bottom: 30px;
                .word-en {
                    font-size: 30px;
                    font-weight: bold;
                    color: #f15a22					;
                    margin-bottom: 5px;
                    display: flex;
                    align-items: center;
                    img {
                        width: 15px;
                        margin-left: 5px;
                    }
                }
                .word-ch {
                    font-size: 16px;
                    color: #212121;
                }
            }
           
        }
    }
</style>